import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faFacebook,
  faInstagram,
  faGitSquare,
} from "@fortawesome/free-brands-svg-icons"

import useMedia from "../../hooks/useMedia"
import Svg from "../Svg"

import styles from "./index.module.scss"
import useTranslation from "../../hooks/useTranslation"

const Footer = () => {
  const { t } = useTranslation("footer")

  const isCopyrightShown = useMedia(
    ["(min-width: 1367px)", "(min-width: 320px)"],
    [false, true],
    false
  )
  const isMobileSvg = useMedia(["(max-width: 767px)"], [true], false)

  return (
    <footer className={styles["footer"]}>
      <div className={styles["container"]}>
        <div className={styles["footer-wrapper"]}>
          <div className={styles["footer-column"]}>
            <Svg.HeaderLogo />
          </div>
          <div className={styles["footer-column"]}>
            <div className={styles["contacts"]}>
              <div className={styles["contacts-column"]}>
                <div className={styles["contacts-item"]}>
                  {isMobileSvg ? <Svg.GEOMobile /> : <Svg.GEO />}
                  <div className={styles["contacts-item__text"]}>
                    <p>{t("visitAddr")}</p>
                   <span>Surbrunnsgatan 10</span>
                    <span>114 27 Stockholm</span>
                  </div>
                </div>
                <div className={styles["contacts-item"]}>
                  <div className={styles["contacts-item__text"]}>
                    <p>{t("postalAddr")}</p>
                    <span>Ängspiren 21</span>
                    <span>13562 Tyresö</span>
                    <span>SWEDEN</span>
                  </div>
                </div>
              </div>
              <div className={styles["contacts-column"]}>
                <div className={styles["contacts-item"]}>
                  {isMobileSvg ? <Svg.MobileMobile /> : <Svg.Mobile />}
                  <div className={styles["contacts-item__text"]}>
                    <p>{t("phone")}</p>
                    <a href="tel:+468396099">+46 8 396 099</a>
                  </div>
                </div>
                <div className={styles["contacts-item"]}>
                  {isMobileSvg ? <Svg.EnvelopeMobile /> : <Svg.Envelope />}
                  <div className={styles["contacts-item__text"]}>
                    <p>{t("email")}</p>
                    <a href="mailto:hello@dynamist.se">
                      hello@dynamist.se
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles["footer-column"]}>
            <div className={styles["icons__wrapper"]}>
              <div className={styles["icons__wrapper__icons"]}>
                <a href="https://twitter.com/dynamister">
                  <Svg.ContactTwitter />
                </a>
                <a href="https://www.facebook.com/dynamister/">
                  <Svg.ContactFacebook />
                </a>
                <a href="https://www.linkedin.com/company/dynamist-ab/">
                  <Svg.ContactLinkedIn />
                </a>
                <a href="https://github.com/dynamist">
                  <Svg.ContactGit />
                </a>
              </div>
              {isMobileSvg ? <Svg.GivingPeopleMobile /> : <Svg.GivingPeople />}
              <div className={styles["icons__wrapper__icons__soff"]}>
                <Svg.Soff />
              </div>
            </div>
          </div>
        </div>
        <div className={styles["copyright"]}>
          <p>{t("copyright")}</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
